import React, { useState, useEffect } from 'react'
import { graphql } from 'gatsby'
import { MDXProvider } from '@mdx-js/react'
import { MDXRenderer } from 'gatsby-plugin-mdx'
import styled from 'styled-components'
import Head from '../components/head'
import Main from '../components/layout'
import {
  A,
  Heading1,
  Heading2,
  Heading3,
  Heading4,
  Heading5,
  // ScrollLink,
  PageSubtitle
} from '../components/typography'

function BlankLink(props) {
  const { children, ...otherProps } = props
  return (
    <A rel="nofollow noopener noreferrer" target="_blank" {...otherProps}>
      {children}
    </A>
  )
}

const StickyToc = styled.nav`
  padding: 2em 0;
  ${
    '' /* position: sticky;
  top: 0; */
  }
`

const Toc = styled.ul`
  > li {
    margin-bottom: 1em;
    font-weight: 700;
    > ul {
      font-size: 0.8em;
      > li {
        margin: 0;
        font-weight: 400;
      }
    }
  }
`

const ContentNav = styled.aside`
  background: var(--cream-dark);
  display: flex;
  flex-direction: column;
  min-width: 24em;
  position: relative;
  padding: 1em 2em 1em var(--bleed-side);

  @media (max-width: 80em) {
    width: 100%;
    padding: 0 1em 1em var(--bleed-side);
  }
`

const ContentWrapper = styled.section`
  display: flex;
  position: relative;
  max-width: 72em;
  min-height: 100vh;

  @media (max-width: 60em) {
    flex-direction: column;
  }
`

const MdxContentWrapper = styled.article`
  padding: var(--bleed-side);

  hr {
    display: none;
  }

  ${Heading3} {
    margin-top: 2em;
  }

  ${Heading2} {
    &:first-child {
      margin-top: 0;
    }

    & + ${Heading3} {
      margin-top: 0;
    }

    & + ${Heading3} span {
      margin-top: 0.888888889em;
    }
  }

  ${Heading4} {
    margin-top: 2em;
    margin-left: 0.55em;
  }

  ${Heading5} {
    color: var(--blue);
    margin-top: 2em;
    margin-left: 0.625em;
  }

  ${Heading4}, ${Heading5} {
    + p {
      margin-left: 0.625em;
    }
  }

  p + p,
  p + ul,
  p + h4,
  ul + p {
    margin-top: 1em;
  }

  ul {
    margin-left: 1.875em;
    li {
      margin-bottom: 0.375em;
    }
  }

  ul li ul {
    margin-left: 1.25em;
    margin-top: 0.25em;
    li {
      margin-bottom: 0.25em;
      position: relative;
      &::before {
        display: block;
        content: '';
        width: 0.3em;
        height: 0.3em;
        border-radius: 50%;
        background: var(--blue);
        position: absolute;
        top: 0.6em;
        left: -1em;
      }
    }
  }

  > ul > li {
    position: relative;
    &::before {
      display: block;
      content: '';
      width: 0.625em;
      height: 0.2em;
      background: var(--green);
      position: absolute;
      top: 0.6em;
      left: -1.25em;
    }
  }
`

// function renderItems(id, items, active) {
//   return (
//     <ul>
//       {items.items?.map((item) => (
//         <li
//           key={`${id}-${item.title}`}
//           className={active === item.url.slice(1) && 'active'}
//         >
//           <ScrollLink url={item.url} title={item.title} />
//           {item.items && renderItems(id, item.items, active)}
//         </li>
//       ))}
//     </ul>
//   )
// }

// function getIds(items) {
//   return items.reduce((acc, item) => {
//     if (item.url) {
//       acc.push(item.url.slice(1))
//     }
//     if (item.items) {
//       acc.push(...getIds(item.items))
//     }
//     return acc
//   }, [])
// }

export default function MdxTemplate({ data: { mdx } }) {
  const { tableOfContents } = mdx
  const { title, subtitle } = mdx.frontmatter

  console.log(tableOfContents)
  const components = {
    h2: Heading2,
    h3: Heading3,
    h4: Heading4,
    h5: Heading5,
    a: BlankLink
  }

  return (
    <Main theme={{ mdx: true }}>
      <ContentWrapper>
        <ContentNav>
          <Head title={`${title}`} />
          <StickyToc>
            <Heading1>{title}</Heading1>
            <PageSubtitle>{subtitle !== '-' && subtitle}</PageSubtitle>
            <Toc>
              {tableOfContents.items.map((item) => (
                <li>
                  <A href={item.url} title={item.title}>
                    {item.title}
                  </A>
                  <ul>
                    {item.items?.map((item) => (
                      <li>
                        <A href={item.url} title={item.title}>
                          {item.title}
                        </A>
                      </li>
                    ))}
                  </ul>
                </li>
              ))}
            </Toc>
          </StickyToc>
        </ContentNav>

        <MdxContentWrapper>
          <MDXProvider components={components}>
            <MDXRenderer>{mdx.body}</MDXRenderer>
          </MDXProvider>
        </MdxContentWrapper>
      </ContentWrapper>
    </Main>
  )
}

export const pageQuery = graphql`
  query templateQuery($id: String) {
    mdx(id: { eq: $id }) {
      id
      body
      tableOfContents
      frontmatter {
        title
        subtitle
        category
      }
    }
  }
`
